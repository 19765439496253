.contentContainer {
}

.heroContainer {
  height: 43.75rem;
}

.heroTitle {
  color: #fff;
  font-size: 3.125rem !important;
  font-weight: 700 !important;
  font-family: "Sans-Serif" !important;
}

.heroDescription {
  color: #fff;
  margin: 20px 20px 20px 0 !important;
  font-family: "Sans-Serif" !important;
}


.heroImgContainer {
  position: relative;

}

.heroImgContainer:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0!important;
  top: 0!important;
  z-index: 3;
  /* background: linear-gradient(90deg, rgba(177,38,39,1) 0%, rgba(0,0,0,0) 100%); */
  background: rgba(0,0,0,0.5);
}

.heroContainerItems {
  position: relative;
  z-index: 10;
}

.heroContainerItemTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.descriptionContainer {
  padding: 0 4.063rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.recentContainer {
  padding: 2.5rem 0;
  background: linear-gradient(90deg, #fff 0%, #cfd6de 100%);
}

.smallHorizontalLine {
  width: 30px;
  border: 2px solid #333;
}

.avatarSection {
  display: flex;
  flex-wrap: wrap;
  padding-top: 45px;
  justify-content: center;
}

.avatarContainer {
  margin: 10px 20px;
  position: relative;
}

.avatarBorder {
  position: absolute;
  border: 4px solid #e5e5e599;
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.avatarItem {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.privacyInnerContainer {
  border-radius: 25px;
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 40px 80px;
}

.privacyBackButton {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.privacyBackButton:hover {
  text-decoration: underline;
}

.squareContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
}

.squareBorder {
  text-align: center;
  border: 1px solid #eee;
  padding: 20px;
  margin: 0 20px;
  height: inherit;
}

.squareIcon {
  margin: 20px 0;
  height: 50px;
  width: auto;
}

.buttonSquareFilledRed {
  color: #fff !important;
  background-color: #b71234 !important;
  border: 1px solid #b71234 !important;
  border-radius: 0 !important;
}

.buttonSquareFilledRed:hover {
  color: #b71234 !important;
  background-color: #fff !important;
}

.gridSpace {
  display: none;
}

@media (max-width: 1440px) {
  .avatarContainer {
    margin: 10px 10px;
  }
}

@media (max-width: 1201px) {
  .squareContentContainer {
    margin-bottom: 40px;
  }

  .squareBorder {
    width: -webkit-fill-available;
  }

  .avatarContainer {
    margin: 10px 10px;
  }
}

@media (max-width: 901px) {
  .heroTitle {
    text-align: center;
  }

  .heroContainerItemTitle {
    align-items: center;
    padding: 0 40px;
  }

  .heroDescription {
    text-align: center;
  }

  .descriptionContainer {
    padding: 20px 10px 0 10px;
    text-align: center;
  }

  .privacyInnerContainer {
    padding: 40px 0;
  }

  .avatarContainer {
    margin: 10px 28px;
  }
}

@media (max-width: 601px) {
  .gridSpace {
    display: block;
  }

  .avatarContainer {
    margin: 10px 28px;
  }
}

@media (max-width: 425px) {
  .avatarContainer {
    margin: 10px 22px;
  }
}